<template lang="pug">
v-card.invitation-card(width="800px" min-height="200px")
  v-card-text.text-center
    v-img.mx-auto(src="../../assets/img/brand/logotipo-dark.png" height="80" contain)
    div(v-if="loading")
      v-progress-circular.mt-8(:size="50" color="primary" indeterminate :width="7")
    div(v-else-if="error")
      .my-4 {{error}}
      v-btn(color="primary" to="/" rounded :loading="loading") Ir al inicio
    div(v-else-if="invitation.status === 'INVITED'")
      //- Usuario registrado
      div(v-if="invitation.user")
        .my-4 {{invitationText}}
        v-row(v-if="invitation.user")
          v-col
            v-btn(color="primary" @click="decline" block outlined rounded :disabled="loading") Rechazar
          v-col
            v-btn(color="primary" @click="accept" block rounded :loading="loading") ¡Aceptar!
      //- Usuario NO registrado
      SignupForm(v-else :defaultValues="{email: invitation.user_email}" title="Registro" submitText="Registrarme y Aceptar Invitación" @success="accept" noRedirect)
    div(v-else)
      .my-4 {{invitationText}}
      v-btn(color="primary" to="/" rounded :loading="loading") Ir al inicio
</template>

<script>
import { mapActions } from 'vuex'
import SignupForm from '../auth/SignupForm.vue'

export default {
  props: {
    invitationToken: {
      type: String,
      required: true
    }
  },
  components: {
    SignupForm
  },
  data () {
    return {
      errorText: '¡Lo sentimos! No pudimos aceptar la invitación.',
      invitation: null,
      loading: true,
      error: null
    }
  },
  methods: {
    ...mapActions('invitation', ['getInvitationByToken', 'acceptInvitation', 'declineInvitation']),
    async getInvitation () {
      const response = await this.getInvitationByToken(this.invitationToken)
      if (response.status === 200) {
        this.invitation = response.data
      } else {
        this.error = 'Lo sentimos, no se pudo obtener la invitación.'
      }
      this.loading = false
    },
    async accept () {
      this.loading = true
      const response = await this.acceptInvitation(this.invitationToken)
      if (response.status === 200 || response.status === 201) {
        this.invitation = response.data
      } else {
        this.error = 'Lo sentimos, no se pudo aceptar la invitación.'
      }
      this.loading = false
    },
    async decline () {
      this.loading = true
      const response = await this.declineInvitation(this.invitationToken, false)
      if (response.status === 200 || response.status === 201) {
        this.invitation = response.data
      } else {
        this.error = 'Lo sentimos, no se pudo rechazar la invitación.'
      }
      this.loading = false
    }
  },
  computed: {
    invitationText () {
      const texts = {
        INVITED: `¡${this.invitation.owner_full_name} te ha invitado a unirte al equipo de ${this.invitation.business_name} en Year2Day!`,
        ACCEPTED: `¡Felicidades! has aceptado la invitación, ahora eres parte de ${this.invitation.business_name}.`,
        REJECTED: `Has rechazado la invitación a ${this.invitation.business_name}.`
      }
      return texts[this.invitation.status]
    }
  },
  async created () {
    await this.getInvitation()
  }
}
</script>

<style lang="scss" scoped>
div {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5;
}

@media (min-width: 600px) {
  .invitation-card {
    padding: 56px;
  }
}
</style>