import { render, staticRenderFns } from "./LearningView.vue?vue&type=template&id=48e2b5f2&scoped=true&lang=pug"
import script from "./LearningView.vue?vue&type=script&lang=js"
export * from "./LearningView.vue?vue&type=script&lang=js"
import style0 from "./LearningView.vue?vue&type=style&index=0&id=48e2b5f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e2b5f2",
  null
  
)

export default component.exports