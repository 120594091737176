<template lang="pug">
div
  v-card.pb-4
    v-card-title
      span Tus Casos de Estudio
      ShowInfoBtn(@click="$refs.infoAlertStudyCases.showAlert()")
      v-spacer
      v-btn(
        color="primary"
        @click="$refs.commercialProjectionForm.openDialog(); globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_COMMERCIAL_PROJECTION)"
        :id="WIZARD_ELEMENTS_ID.BTN_CREATE_COMMERCIAL_PROJECTION"
      )
        v-icon.mr-2 mdi-plus-circle-outline
        span Añadir Caso
    v-card-text
      InfoAlert(:message="alertMessageStudyCases" ref="infoAlertStudyCases")
    template(v-if="!isEmpty")
      v-simple-table(key="customer-classification")
        template(v-slot:default)
          thead(key="customer-classification-header")
            tr
              th ID
              th Nombre
              th Potencial comercial
              th Mix optimo
              th Plan de ventas
              th Línea de Negocio
              th Fecha de Creación
              th(style="width: 400px") Acciones
          tbody(key="customer-classification-body")
            tr(v-for="item, index in items" :key="item.id")
              td {{item.id}}
              td {{item.name}}
              td {{item.commercial_potential | currency }}
              td
                div
                  b Venta total:
                  span  {{item.optimal_mix_total | currency}}
                div
                  b Margen bruto:
                  span  {{item.optimal_mix_profit | currency}}
              td
                div
                  b Venta total:
                  span  {{item.estimated_sale | currency}}
                div
                  b Margen bruto:
                  span  {{item.estimated_sale_profit | currency}}
              td {{item.line_of_business_names.join(', ')}}
              td {{formatDate(new Date(item.created_at))}}
              td
                v-btn.mr-2(
                  rounded small
                  @click="$router.push(`/proyeccion-comercial/${item.id}/dashboard`) && globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_ANALIYSIS_COMMERCIAL_PROJECTION)"
                  color="primary" :id="index === 0 ? WIZARD_ELEMENTS_ID.BTN_ANALIYSIS_COMMERCIAL_PROJECTION : null")
                  v-icon.mr-2(small)
                    | mdi-view-dashboard-outline
                  span Análisis

                v-btn.mr-2(rounded small @click="$router.push(`/proyeccion-comercial/${item.id}/items`)" color="primary" outlined)
                  v-icon.mr-2(small)
                    | mdi-eye-arrow-right
                  span Datos Cargados


                v-menu(offset-y left)
                  template(v-slot:activator="{ on, attrs }")
                    v-btn(icon v-bind="attrs" v-on="on" :disabled="busyExporting")
                      v-icon mdi-dots-vertical
                  v-list
                    v-list-item(@click="$refs.commercialProjectionForm.openDialog(item.id)")
                      v-list-item-title Editar
                    v-list-item(@click="deleteItem(item.id)")
                      v-list-item-title Eliminar
                    v-list-item(@click="exportItem(item)" :disabled="busyExporting")
                      v-list-item-title Exportar datos
      v-pagination(v-model="pagination.page" :length="pagination.pages" @input="fetchItems")
    v-card-title
      span Desempeño de tus Casos de Estudio
      ShowInfoBtn(@click="$refs.infoAlertPerformanceStudyCases.showAlert()")
    v-card-text
      InfoAlert(:message="alertMessagePerformanceStudyCases" ref="infoAlertPerformanceStudyCases")
    ChartProjectionComparison(v-if="!isEmpty")
    EmptyState(
      v-else-if="noProducts || noCustomers"
      title="Casos de Potencial Comercial"
      description="Aun no tienes clientes o productos registrados, comienza creando un cliente o producto para poder crear un caso de potencial comercial."
      :primaryActionText="noCustomers ? 'Crear Cliente' : null"
      :secondaryActionText="noProducts ? 'Crear Producto' : null"
      imgPath="/img/empty_state_3.png"
      @click-primary-action="$router.push('/clientes')"
      @click-secondary-action="$router.push('/productos')"
    )
    EmptyState(
      v-else
      title="Casos de Potencial Comercial"
      description="Completa primero la información de tus clientes y productos para que puedas crear y analizar casos de potencial comercial."
      primaryActionText="Crear Caso"
      imgPath="/img/empty_state_4.png"
      @click-primary-action="$refs.commercialProjectionForm.openDialog()"
    )
    StudyCasesExamples
    CommercialProjectionForm(ref="commercialProjectionForm" @save="fetchItems")
    CustomConfirmDialog(ref="confirmDialog")

  #case-to-print.v-application.invisible(ref="caseToPrint")
    .case-section
      v-row
        v-col(cols="12" md="6" style="background-color: #f5f5f5")
          .ml-3.mt-3
            CommercialProjectionByCustomerChart.mb-4(:filters="{}" :projection="selectedProjection")
        v-col(cols="12" md="6" style="background-color: #f5f5f5")
            TableProductTop(:filters="{}" :projection="selectedProjection")
        v-col(cols="12" md="12")
          //- HeatMap(:filters="{}" :projection="selectedProjection")
          MarketSize(:filters="{}" :projection="selectedProjection")

    .case-section
      ChartOptimalMix(:filters="{}" :totalYearProfit="totalYearProfit" :projection="selectedProjection")
      TableOptimalMix(:filters="{}" @setTotalYearProfit="totalYearProfit = $event" :projection="selectedProjection")

    .case-section
      SalePlanTab(:filters="{}" :projection="selectedProjection")
</template>

<script>
import { mapActions } from 'vuex'
import CommercialProjectionForm from '@/components/commercial_projection/CommercialProjectionForm.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import { formatDate } from '@/utils/dateUtils.js'
import EmptyState from '../common/EmptyState.vue'
import ShowInfoBtn from '../common/ShowInfoBtn.vue'
import InfoAlert from '../common/InfoAlert.vue'
import html2pdf from 'html2pdf.js'
import ChartProjectionComparison from '@/components/commercial_projection/charts/ChartProjectionComparison.vue'

import CommercialProjectionByCustomerChart from '@/components/commercial_projection/charts/CommercialProjectionByCustomerChart.vue'
import TableProductTop from '@/components/commercial_projection/charts/TableProductTop.vue'
import MarketSize from '@/components/commercial_projection/charts/MarketSize.vue'
import HeatMap from '@/components/commercial_projection/charts/HeatMap.vue'
import ChartOptimalMix from '@/components/commercial_projection/charts/ChartOptimalMix.vue'
import TableOptimalMix from '@/components/commercial_projection/charts/TableOptimalMix.vue'
import SalePlanTab from '@/components/commercial_projection/SalePlanTab.vue'
import JSZip from 'jszip'
import fileDownload from 'js-file-download'

import { WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'
import StudyCasesExamples from '../common/StudyCasesExamples.vue'

export default {
  components: {
    CommercialProjectionForm,
    CustomConfirmDialog,
    EmptyState,
    ShowInfoBtn,
    InfoAlert,

    CommercialProjectionByCustomerChart,
    MarketSize,
    TableProductTop,
    HeatMap,
    ChartOptimalMix,
    TableOptimalMix,
    SalePlanTab,
    ChartProjectionComparison,
    StudyCasesExamples,
  },
  data () {
    return {
      WIZARD_STEPS_EVENTS,
      WIZARD_ELEMENTS_ID,
      alertMessageStudyCases: 'En esta sección, podrás analizar el desempeño de todos tus casos de estudio creados, además de explorar en detalle el análisis de cada uno. Recuerda que dentro de los análisis encontrarás tableros de resultados interactivos, y tendrás la opción de crear y personalizar tu plan de ventas basado en los datos obtenidos. Puedes editar o exportar los datos del caso cuando lo desees. Al exportar, los datos incluyen un informe con los resultados principales.',
      alertMessagePerformanceStudyCases: 'En esta sección, podrás comparar el desempeño de todos tus casos de estudio creados. Para un análisis completo, recomendamos que primero cada caso tenga un plan de ventas creado. ',
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 20
      },
      filter: {
        search: '',
        base_product__line_of_business: null
      },
      items: [],
      isEmpty: false,
      noCustomers: false,
      noProducts: false,
      selectedProjection: null,
      totalYearProfit: 0,
      busyExporting: false,
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('commercial_projection', [
      'listCommercialProjections',
      'deleteCommercialProjection',
      'downloadOptimalMix',
      'downloadSalePlan',
      'downloadProjectionItems',
    ]),
    ...mapActions('product', ['listProducts', 'downloadProductCapacity', 'downloadProducts']),
    ...mapActions('customer', ['listCustomers', 'downloadCustomers']),
    formatDate,
    async fetchItems () {
      const params = {
        ...this.filter,
        page: this.pagination.page,
        page_size: this.pagination.pageSize
      }
      const { data } = await this.listCommercialProjections({ params })
      this.items = data.results || []
      if (this.items.length !== 0) {
        this.isEmpty = false
      }
      this.pagination.pages = Math.ceil(data.count / this.pagination.pageSize)
    },
    async handleFilter () {
      this.pagination.page = 1
      await this.fetchItems()
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar Caso de Consumo Potencial',
        '¿Está seguro que desea eliminar este caso de consumo potencial?',
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteCommercialProjection(id)
        await this.fetchItems()
      }
    },
    async checkHasCustomers () {
      const { data } = await this.listCustomers({ params: { page_size: 1 } })
      this.noCustomers = data.results.length === 0
    },
    async checkHasProducts () {
      const { data } = await this.listProducts({ params: { page_size: 1 } })
      this.noProducts = data.results.length === 0
    },
    async exportItem (item) {
      this.busyExporting = true
      this.selectedProjection = item.id
      this.$refs.caseToPrint.classList.toggle('invisible')
      await this.$nextTick()
      // await new Promise(resolve => setTimeout(resolve, 4000))

      const opt = {
        pagebreak: {
          mode: 'css',
          after: ['.case-section:not(:last-child)'],
          avoid: ['td', 'tr']
        },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true
        },
        margin: 4
      }
      html2pdf().set(opt).from(this.$refs.caseToPrint).toPdf().get('pdf').then(async (pdf) => {
        const pdfBlob = await pdf.output('blob')
        const xlsOptimalMix = await this.downloadOptimalMix({ projection: item.id })
        const xlsSalePlan = await this.downloadSalePlan({ projection: item.id })
        const xlsProjectionItems = await this.downloadProjectionItems(item.id)
        const xlsCustomers = await this.downloadCustomers({ projection: item.id })
        const xlsProducts = await this.downloadProducts({ projection: item.id })
        const xlsCapacity = await this.downloadProductCapacity({ projection: item.id })

        const zip = new JSZip()
        const folder = zip.folder(`Caso ${item.name}`)
        folder.file('Resumen.pdf', pdfBlob)
        folder.file('Mix Óptimo.xlsx', xlsOptimalMix.data)
        folder.file('Plan Ventas.xlsx', xlsSalePlan.data)
        folder.file('Items Proyección.xlsx', xlsProjectionItems.data)
        folder.file('Clientes.xlsx', xlsCustomers.data)
        folder.file('Productos.xlsx', xlsProducts.data)
        folder.file('Capacidad Productiva.xlsx', xlsCapacity.data)


        zip.generateAsync({ type: 'blob' }).then((content) => {
          fileDownload(content, `Caso ${item.name}.zip`)
        })
        this.busyExporting = false
      })

      await this.$nextTick()
      this.$refs.caseToPrint.classList.toggle('invisible')
    },
  },
  async created () {
    await this.fetchItems()
    if (this.items.length === 0) {
      this.$refs.infoAlert.showAlert()
      this.isEmpty = true
    }
    this.checkHasCustomers()
    this.checkHasProducts()
  }
}
</script>

<style>
#case-to-print {
  display: block;
  width: 760px;
}
</style>

<style scoped>
.invisible {
  opacity: 0;
}
</style>
