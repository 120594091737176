<template lang="pug">
div
  component(:is="layout" v-if="!loading")
    router-view(:key="key" :layout.sync="layout")
  Wizard
</template>

<script>
import DefaultLayout from './layouts/DefaultLayout'
import EmptyLayout from './layouts/EmptyLayout'
import { mapGetters, mapActions } from 'vuex'
import Wizard from './components/common/Wizard.vue'

export default {
  name: 'App',
  components: {
    DefaultLayout,
    EmptyLayout,
    Wizard
  },
  data: () => ({
    key: 'default'
  }),
  computed: {
    ...mapGetters('app_config', ['currentBusiness']),
    ...mapGetters('app', ['loading', 'businessId']),
    layout () {
      return this.$route.meta?.layout || 'DefaultLayout'
    }
  },
  methods: {
    ...mapActions('app', ['setup'])
  },
  watch: {
    businessId () {
      if (this.businessId) {
        this.key = this.businessId.toString()
      } else {
        this.key = 'default'
      }
    },
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Plan2Sell';
      }
    }
  },
  async created () {
    await this.setup()
  }
}
</script>

<style lang="scss">
@import '@/styles/utility.scss';
.v-main {
  background-color: #dee5ed !important;
  color: red !important;
}
.container {
  padding: 24px;
}
</style>
