<template lang="pug">
v-container#login(fluid)
  v-card(width="500px")
    v-card-text.pa-md-14.pa-7
      v-img.mx-auto(src="../assets/img/brand/logotipo-dark.png" height="80" contain)
      LoginForm.mb-4
      .text-center
        span.font-weight-medium ¿No tienes una cuenta?
      .text-center
        router-link(to="/registro").text-decoration-none.font-weight-medium Registrate aquí
      .more ¿Tienes una consulta o sugerencia? Visitanos en
          a(href="https://www.year2day.com" target="_blank")  www.year2day.com
          span  o escríbenos a
          a  contacto@year2day.com
</template>

<script>
import LoginForm from '../components/auth/LoginForm.vue'

export default {
  components: {
    LoginForm
  }
}
</script>

<style scoped lang="scss">
.more {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: -30px;
  a {
    text-decoration: none;
  }
}

#login {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  #login {
    padding: 0 !important;
    background-color: white;
  }
  .v-card {
    border-radius: 0 !important;
  }
}
</style>
