<template lang="pug">
v-container(fluid style="overflow: show;")
  v-row
    v-col(cols="12")
      v-card.homeBanner.d-flex.align-center
        v-card-text
          h1.white--text PARA APRENDER
  v-row
    v-col(cols="12" md="4" lg="4")
      v-card.d-flex.learningCard.learningUseManualCard.flex-column.pa-2
        v-card-title.d-flex.justify-space-between.mt-4
          .learningTitle Manual de uso
          v-icon(color="#fff" size="40px") mdi-book-open-variant
        v-card-text
          p.white--text.description A continuación, encontrarás una guía detallada para facilitarte el uso de las principales funcionalidades y herramientas disponibles de Plan2Sell. Este manual está diseñado para garantizar que saques el máximo provecho de la plataforma, optimizando tu experiencia de usuario.
          v-btn(outlined @click="downloadUserManual" style="background-color: #fff" disabled) PRÓXIMAMENTE
    v-col(cols="12" md="4" lg="4")
      v-card.d-flex.learningCard.learningBrochureCard.flex-column.pa-2
        v-card-title.d-flex.justify-space-between.mt-4
          .learningTitle Brochure
          v-icon(color="#fff" size="40px") mdi-notebook-heart
        v-card-text
          p.white--text.description Optimiza tu gestión comercial y descubre el potencial de tu mercado. Descarga el brochure de Plan2Sell y accede a tips para aprovechar la plataforma. Aprende a gestionar datos, generar reportes y tomar decisiones exitosas. ¡Descárgalo ahora!
          br
          v-btn(color="#5575DF" dark outlined @click="downloadBrochure" style="background-color: #fff") OBTENER
    v-col(cols="12" md="4" lg="4")
      v-card.d-flex.learningCard.learningTutorialCard.flex-column.pa-2
        v-card-title.d-flex.justify-space-between.mt-4
          .learningTitle Video Tutoriales
          v-icon(color="#fff" size="40px") mdi-message-video
        v-card-text
          p.white--text.description ¿Prefieres aprender con videos? Accede a nuestro canal de video tutoriales y descubre cómo dominar Plan2Sell de manera rápida y sencilla. Aprende paso a paso cómo utilizar cada función para maximizar tus resultados. ¡Haz clic aquí y comienza a explorar!
          br
          v-btn(outlined @click="goToURL" style="background-color: #fff" disabled) PRÓXIMAMENTE
  div
    StudyCasesExamples
</template>
<script>
import StudyCasesExamples from '../components/common/StudyCasesExamples.vue'

export default {
  name: 'LearningView',
  components: {
    StudyCasesExamples
  },
  methods: {
    goToURL () {
      window.open('https://www.youtube.com', '_blank')
    },
    downloadBrochure () {
      const link = document.createElement('a')
      link.href = 'brochure.pdf'
      link.setAttribute('download', 'brochure.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>
<style lang="scss" scoped>
.learningCard {
  color: #fff !important;
  height: 300px;
  cursor: pointer;
  @media (max-width: 1024px) {
    min-height: 400px;
  }
}
.learningTitle {
  color: #fff !important;
  font-size: 28px;
  font-weight: 700;
}
.learningUseManualCard {
  background: rgb(49,183,194);
  background: linear-gradient(90deg, rgba(49,183,194,1) 0%, rgba(123,195,147,1) 100%);
}
.learningBrochureCard {
  background: rgb(127,127,213);
  background: linear-gradient(90deg, rgba(127,127,213,1) 0%, rgba(145,234,228,1) 100%);
}
.learningTutorialCard {
  background: rgb(18,194,233);
  background: linear-gradient(90deg, rgba(18,194,233,1) 0%, rgba(246,79,89,1) 100%);
}
.description {
  font-size: 15px;
  line-height: 18.75px;
}
</style>