<template lang="pug">
div
  v-card.mt-6
    v-card-text
      h3.mb-4 Ejemplos de Uso: Casos de Estudio
      .d-flex.pb-4(style="gap: 16px; overflow-x: auto;")
        v-card(
          v-for="example in HOME_INFO_CARDS"
          min-width="175"
          outlined
        )
          v-card-text.pa-3.d-flex.flex-column.justify-space-between(style="height: 100%;")
            div
              v-icon.mb-2(size="35px" color="primary") {{ example.icon }}
              div {{ example.text1 }}
              h1.mb-4(style="color: #1B2E38;") {{ example.text2 }}
            div
              v-chip.d-block.rounded-lg.text-center(small text-color="#5575DF" color="#DFE7FA" @click="showExample(example)") Revisar Caso
  v-dialog(v-model="exampleModal" max-width="700px")
    v-card(v-if="selectedExample")
      v-card-title Casos de Estudio
      v-card-text
        h2.mb-4(style="color: #5575DF;") {{ selectedExample.title }}
        p(v-html="selectedExample.example")
        .text-right
          v-btn(color="primary" @click="exampleModal = false") Cerrar
</template>
<script>
import { HOME_INFO_CARDS } from '@/constants/home'
export default {
  data() {
    return {
      HOME_INFO_CARDS,
      exampleModal: false,
      selectedExample: null,
    }
  },
  methods: {
    showExample(example) {
      this.selectedExample = example
      this.exampleModal = true
    },
  },
}
</script>