<template lang="pug">
v-card
  v-card-title
    span Mis Planes de Venta
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert")
  v-simple-table
    template(v-slot:default)
      thead
        tr
          th Nombre
          th
          th Tipo
          th Año
          th Estimación
          th Acciones
      tbody
        tr(v-for="plan in plans" @click="$router.push(`/planes/${plan.id}/`)" :key="plan.id")
          td {{plan.name}}
          td
            v-btn.mr-2(
              rounded small
              @click="$router.push(`/planes/${plan.id}/analisis`)"
              color="primary")
              v-icon.mr-2(small)
                | mdi-view-dashboard-outline
              span Análisis
          td {{plan.plan_type}}
          td {{plan.year}}
          td {{plan.estimation | planEstimation(plan.plan_type)}}
          td
            v-menu(offset-y)
              template(v-slot:activator="{ on, attrs }")
                v-btn(v-bind="attrs" v-on="on" icon)
                  v-icon mdi-dots-vertical
              v-list
                //- v-list-item(:to="`/planes/editar/${plan.id}/`")
                //-   v-list-item-title Editar
                v-list-item(@click="$refs.removePlanDialog.open(plan.id)")
                  v-list-item-title Eliminar
  .text-center.py-4
    v-pagination(v-model="currentPage" :length="paginationLength")
  RemovePlanDialog(ref="removePlanDialog" @save="fetchData")
</template>

<script>
import { mapActions } from 'vuex'
import PaginationMixin from '../mixins/PaginationMixin.vue'
import RemovePlanDialog from './dialogs/RemovePlanDialog.vue'
import ShowInfoBtn from '@/components/common/ShowInfoBtn.vue'
import InfoAlert from '@/components/common/InfoAlert.vue'

export default {
  mixins: [PaginationMixin],
  components: {
    RemovePlanDialog,
    ShowInfoBtn,
    InfoAlert,
  },
  data () {
    return {
      plans: [],
      alertMessage: 'Una vez que hayas generado tu plan de ventas para seguimiento (Casos y Análisis → Análisis → Plan de ventas → Acciones → Generar plan de ventas para seguimiento), este aparecerá en esta sección. Aquí podrás analizar el rendimiento de tu plan comparando los resultados reales con lo planificado, lo que te permitirá ajustar tus estrategias basándote en datos concretos de ventas.'
    }
  },
  methods: {
    ...mapActions('plan', ['listPlan']),
    async fetchData () {
      const params = {
        type: 'sale'
      }
      const response = await this.listPlan({ params })
      this.plans = response.data.results
      this.itemCount = response.data.count
    },
  },
  created () {
    this.fetchData()
  }
}
</script>
