<template lang="pug">
v-container#plan-view(fluid)
  v-card(flat)
    v-card-text
      .d-flex
        v-spacer
        v-menu(offset-y bottom left)
          template(v-slot:activator="{ on, attrs }")
            v-btn(color="primary" v-bind="attrs" v-on="on") Nuevo Plan
          v-list
            //- v-tooltip(left)
            //-   template(v-slot:activator="{ on, attrs }")
            //-     v-list-item(to="/nuevo-plan/ventas" v-bind="attrs" v-on="on")
            //-       v-list-item-title Crear Plan de Ventas
            //-   span El plan de ventas es la carta de navegación de tu equipo de ventas, con él podrás ir monitoreando diaria o semanalmente el cumplimiento de tus metas, que con el tiempo te llevarán a la meta anual definida.
            v-tooltip(left)
              template(v-slot:activator="{ on, attrs }")
                v-list-item(to="/nuevo-plan/costos" v-bind="attrs" v-on="on")
                  v-list-item-title Crear Plan de Costos
              span El plan de costos es ...
            v-tooltip(left)
              template(v-slot:activator="{ on, attrs }")
                v-list-item(to="/nuevo-plan/produccion" v-bind="attrs" v-on="on")
                  v-list-item-title Crear Plan de Producción
              span El plan de producción es ...
    v-tabs.ml-4(v-model="tab" color="primary")
      v-tab Ventas
      v-tab Costos y Producción
    v-tabs-items(v-model="tab")
      v-tab-item
        v-card
          v-card-text
            ListPlan
      v-tab-item
        v-card
          v-card-text
            ListCostAndProductionPlan
</template>

<script>
import ListPlan from '../components/plan/ListPlan.vue'
import ListCostAndProductionPlan from '../components/plan/ListCostAndProductionPlan.vue'

export default {
  components: {
    ListPlan,
    ListCostAndProductionPlan
  },
  data () {
    return {
      tab: 0
    }
  }
}
</script>