<template lang="pug">
v-card.pb-4
  v-card-title
    span Clientes
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
    v-spacer
    v-btn.mr-2(:disabled="!selectedItems.length" color="primary" @click="deleteSelectedItems" outlined)
      v-icon.mr-2 mdi-delete
      span Eliminar Selección
    v-btn.mr-2(color="primary" @click="$refs.customerFormModal.openDialog(); globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_CLIENT)" :id="WIZARD_ELEMENTS_ID.BTN_CREATE_CLIENT")
      v-icon mdi-note-edit-outline
      span Nuevo Cliente
    v-btn(color="primary" @click="$refs.customerImportFormModal.openDialog()")
      v-icon mdi-import
      span Importar
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert")
  div(v-if="!isEmpty")
    v-simple-table(v-if="!isEmpty" key="customer-classification")
      template(v-slot:default)
        thead(key="customer-classification-header")
          tr
            th
              v-checkbox(
                @change="toggleSelectAll"
                v-model="selectAll"
              )
            th Nombre
            th Clasificación
            th(style="width: 80px") Acciones
        tbody(key="customer-classification-body")
          tr(v-for="item in items" :key="item.id")
            td
              v-checkbox(
                v-model="item._selected"
              )
            td {{item.name}}
            td {{item.classification_name}}
            td
              v-menu(offset-y left)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon v-bind="attrs" v-on="on")
                    v-icon mdi-dots-vertical
                v-list
                  v-list-item(@click="$refs.customerFormModal.openDialog(item.id)")
                    v-list-item-title Editar
                  v-list-item(@click="deleteItem(item.id)")
                    v-list-item-title Eliminar
    v-pagination(v-model="pagination.page" :length="pagination.pages" @input="fetchItems")
  EmptyState(
    v-else
    title="Clientes"
    description="Aun no tienes clientes registrados, crea tu primer cliente."
    primaryActionText="Crear Cliente"
    imgPath="/img/empty_state_2.svg"
    @click-primary-action="$refs.customerFormModal.openDialog()"
  )
  CustomerFormModal(ref="customerFormModal" @save="fetchItems")
  CustomConfirmDialog(ref="confirmDialog")
  CustomerImportFormModal(ref="customerImportFormModal" @save="fetchItems")
</template>

<script>
import { mapActions } from 'vuex'
import CustomerFormModal from '@/components/customers/CustomerFormModal.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import CustomerImportFormModal from '@/components/customers/CustomerImportFormModal.vue'
import ShowInfoBtn from '../common/ShowInfoBtn.vue'
import InfoAlert from '../common/InfoAlert.vue'
import EmptyState from '../common/EmptyState.vue'
import TableMultipleSelectionMixin from '@/components/mixins/TableMultipleSelectionMixin.vue'
import { CASE_MODIFICATION_WARNING, WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  mixins: [TableMultipleSelectionMixin],
  components: {
    CustomerFormModal,
    CustomConfirmDialog,
    CustomerImportFormModal,
    ShowInfoBtn,
    InfoAlert,
    EmptyState
  },
  data () {
    return {
      WIZARD_ELEMENTS_ID,
      WIZARD_STEPS_EVENTS,
      alertMessage: 'Este es tu centro de gestión de la base de datos de clientes. Es crucial asignar correctamente la comuna a cada cliente para reflejar con precisión el potencial de mercado en el mapa. Asegúrate de haber definido previamente la clasificación de clientes para poder categorizarlos según estos atributos. Si decides importar una planilla, verifica que los campos coincidan exactamente con los que ya has agregado a la base de datos.',
      items: [],
      isEmpty: false,
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 20
      },
    }
  },
  methods: {
    ...mapActions('customer', ['listCustomers', 'deleteCustomer', 'bulkDeleteCustomers']),
    globalEmitter,
    async fetchItems () {
      const params = {
        page: this.pagination.page,
        page_size: this.pagination.pageSize
      }
      const { data } = await this.listCustomers({ params })
      this.items = data.results || []
      if (this.items.length !== 0) {
        this.isEmpty = false
      }
      this.pagination.pages = Math.ceil(data.count / this.pagination.pageSize)
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar cliente',
        '¿Está seguro que desea eliminar este cliente?. '  + CASE_MODIFICATION_WARNING,
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteCustomer(id)
        await this.fetchItems()
      }
    },
    async deleteSelectedItems() {
      const ok = await this.$refs.confirmDialog.open(
        'Eliminar productos',
        '¿Está seguro que desea eliminar los clientes seleccionados?. '  + CASE_MODIFICATION_WARNING,
        {
          okTitle: 'Eliminar',
          icon: 'mdi-delete'
        }
      )

      if (!ok) return

      const { error } = await this.bulkDeleteCustomers(this.selectedItems.map(item => item.id))
      if (error) {
        this.$toast.error('No se pudieron eliminar los clientes seleccionados')
        return
      }

      this.$toast.success('Clientes eliminados correctamente')
      this.fetchItems()
    }
  },
  async created () {
    await this.fetchItems()
    if (this.items.length === 0) {
      this.isEmpty = true
      this.$refs.infoAlert.showAlert()
    }
  }
}
</script>