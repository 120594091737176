<template lang="pug">
v-container(fluid style="overflow: show;")
  v-row
    v-col(cols="12")
      v-card(style="background: rgb(85,117,223); background: linear-gradient(0deg, rgba(85,117,223,1) 0%, rgba(67,163,202,1) 50%, rgba(49,208,182,1) 100%); position: relative;")
        v-card-text
          h3.white--text.mb-2 Wizard Plan 2 Sell
          h1.white--text.mb-4(style="line-height: 1.1;")
            | Explora la herramienta y descubre el potencial de tu negocio.
          v-btn(color="primary" @click="openWizard()" outlined style="background-color: white") COMENZAR
        v-img(src="/img/wizard-modal.png" alt="hero" width="120px" style="position: absolute; right: 20px; bottom: 0;")
  v-row(@click="openConfigBussiness = !openConfigBussiness")
    v-col(cols="12")
      v-card.homeBanner.d-flex.align-center
        v-card-text.d-flex.justify-space-between
          h1.white--text.mt-2 Configura tu Negocio
          v-icon.white--text(size="40px") mdi-chevron-down
  v-row(v-if="openConfigBussiness")
    v-col(lg="4" md="4" sm="12" cols="12")
      v-card
        v-card-title.cardTitleHome
          v-btn.ma-2(small color="#19BFA7" fab dark elevation="0")
            v-icon(size="40px") mdi-numeric-1
          h4.white--text INGRESA TUS CLIENTES
        v-card-text
          p.cardHomeText ¡Personaliza la clasificación de tus clientes según el tamaño, sector, o cualquier otro criterio relevante! Esto te permitirá filtrar y analizar tu estudio de mercado con mayor precisión, basándote en las características específicas que definas. Agrega por planilla o manualmente al modelo tu base de datos de clientes actuales o potenciales.
          v-btn.btnCardHome.mt-4(block color="primary" @click="$router.push({name: 'Clientes'})") Ingresa a tus clientes
          v-btn.btnCardHome.mt-4(block color="primary" @click="$router.push({name: 'Clientes'})") Clasifica a tus clientes
    v-col(lg="4" md="4" sm="12" cols="12")
      v-card
        v-card-title.cardTitleHome
          v-btn.ma-2(small color="#19BFA7" fab dark elevation="0")
            v-icon(size="40px") mdi-numeric-2
          h4.white--text INGRESA TUS PRODUCTOS
        v-card-text
          p.cardHomeText Organiza tus productos en líneas de negocio específicas y crea una base de datos eficiente. Con Plan2Sell, puedes manejar productos con múltiples variantes utilizando atributos comunes. Por ejemplo, si vendes camisetas en diferentes tallas y colores, Plan2Sell te permite agrupar estos formatos. Así, no solo analizarás el rendimiento del producto general (camiseta), sino también sus variantes específicas, como la camiseta roja talla L. El producto principal se denomina "producto base" y cada variante será un “producto para venta (SKU)”
          v-btn.btnCardHome.mt-4(block color="primary" @click="$router.push({name: 'Productos'})") Crea tu línea de negocio
          v-btn.btnCardHome.mt-4(block color="primary" @click="$router.push({name: 'Productos'})") Crea productos base
          v-btn.btnCardHome.mt-4(block color="primary" @click="$router.push({name: 'Productos'})") Crea productos para ventas (SKU)
    v-col(lg="4" md="4" sm="12" cols="12")
      v-card
        v-card-title.cardTitleHome.d-flex.flex-row.flex-nowrap
          v-btn.ma-2(small color="#19BFA7" fab dark elevation="0")
            v-icon(size="40px") mdi-numeric-3
          .d-flex.flex-column
            h5.white--text.text-center INGRESA LA CAPACIDAD DE
            h5.white--text.text-center PRODUCIR O ABASTECIMIENTO DE
            h5.white--text.text-center CADA PRODUCTO
        v-card-text
          p.cardHomeText La gestión de capacidades le otorgará restricciones al modelo de optimización avanzado de Plan2Sell. Estas capacidades de producción o abastecimiento será tu límite máximo de venta para cada producto, para que el resultado entregado por Plan2Sell sea lo más realista de acuerdo a las características específicas de tu negocio. Puedes asignar capacidades compartidas, que involucran varios productos que utilizan los mismos recursos o maquinarias. El modelo inteligente decidirá eficientemente en que productos conviene enfocar los esfuerzos. Puedes especificar cómo variará mes a mes, con nuestra función de estacionalidad.
          v-btn.btnCardHome.mt-4(block color="primary" @click="$router.push({name: 'Capacidad de Producción'})") Ingreso de capacidad unitaria o compartida de productos
          v-btn.btnCardHome.mt-4(block  color="primary" @click="$router.push({name: 'Capacidad de Producción'})") Asigna estacionalidad a tus capacidades

  v-row(@click="openStudyCases = !openStudyCases")
    v-col(cols="12")
      v-card.homeBanner.d-flex.align-center
        v-card-text.d-flex.justify-space-between
          h1.white--text.mt-2 Casos de Estudio
          v-icon.white--text(size="40px") mdi-chevron-down
  v-row(v-if="openStudyCases")
    v-col(cols="12")
      v-card.pa-4
        div.homeTitle
          h2 CREACIÓN DEL CASOS
        v-card-text.text-justify
          p.caseCreationMessage {{ caseCreationMessage}}
    v-col(cols="12")
      div.homeTitle
        h2 ANÁLISIS Y RESULTADOS
      v-row.mt-4
        v-col(v-for="infocard in mainInfoCard" lg="" md="4" sm="6" cols="12")
          v-card.text-center.cardStudyCases
            v-card-text
              v-icon(size=60 color="#1B2E38") {{ infocard.icon }}
              h2.mt-4.text-center(style="color: #19BFA7;") {{ infocard.title }}
            v-card-text.px-md-5.text-start
              p.cardHomeDescriptionText {{ infocard.description }}

  v-row(@click="openPlanification = !openPlanification")
    v-col(cols="12")
      v-card.homeBanner.d-flex.align-center
        v-card-text.d-flex.justify-space-between
          h1.white--text.mt-2 Planificación y Seguimiento
          v-icon.white--text(size="40px") mdi-chevron-down
  v-row(v-if="openPlanification")
    v-col(cols="12")
      v-card.pa-4
        div.homeTitle
          h2 EXPORTACIÓN PLAN DE VENTAS
        v-card-text.text-justify
          p.caseCreationMessage Exporta tu plan de ventas final para realizarle seguimiento.
    v-col
      div.homeTitle
        h2 PLANIFICACIÓN Y SEGUIMIENTO
      v-row.mt-4
        v-col(lg="4" md="4" sm="4" cols="12" v-for="infoPlanificationCard in mainPlanificationInfoCard")
          v-card.text-center.planificationCard
            v-card-text
              v-icon(size=60 color="#1B2E38") {{ infoPlanificationCard.icon }}
              h2.mt-4.text-center(style="color: #19BFA7;") {{ infoPlanificationCard.title }}
            v-card-text.px-md-5.text-start
              p.cardHomeDescriptionText {{ infoPlanificationCard.description }}
</template>

<script>
import { WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  name: 'Home',
  data() {
    return {
      openConfigBussiness: false,
      openStudyCases: false,
      openPlanification: false,
      mainInfoCard: [
        {
          title: 'POTENCIAL COMERCIAL',
          description: `Analiza el tamaño total del mercado.
Identifica oportunidades de crecimiento.
Visualiza y cuantifica tu mercado objetivo.
Ver productos y clientes con más participación.
Analiza zonas de mayor potencial.
Evalúa el crecimiento del mercado.`,
          icon: 'mdi-star-plus',
        },
        {
          title: 'MIX ÓPTIMO',
          description: `Descubre la combinación más rentable.
Considera las capacidades, precios y costos de tu negocio.
Utiliza tecnología de punta con un modelo de optimización avanzado.
Maximiza tu margen bruto`,
          icon: 'mdi-tune-vertical',
        },
        {
          title: 'PLAN DE VENTAS',
          description: `Personaliza tu estrategia de ventas.
Ajusta según análisis continuos. Enfócate en el crecimiento sostenible.
Crea desde cero o modifica desde el mix óptimo. Exporta el plan para seguimiento y control.`,
          icon: 'mdi-file-chart-check',
        },
        {
          title: 'COMPARACIÓN DE CASOS',
          description: `Compara visualmente los diferentes casos de estudio que has creado, evaluando el desempeño de cada uno.
Esto te permitirá tomar decisiones estratégicas informadas y determinar cuáles acciones son más relevantes para el éxito de tu negocio. Además,
podrás definir cuál plan de ventas será el definitivo para su implementación.`,
          icon: 'mdi-chart-bar'
        },
        {
          title: 'INFORMES DESCARGABLES',
          description: `Plan2Sell te permite descargar cada caso para
generar informes de planificación y dirección, así como para guardar las bases de datos. De este
modo, podrás eliminarlos y volver a cargarlos en el futuro cuando lo necesites, garantizando siempre acceso y control sobre tus análisis.`,
          icon: 'mdi-file-download'
        }
      ],
      caseCreationMessage: `¡Crea tu caso de estudio! \n
      Crea casos personalizados para analizar el potencial comercial de tus productos. Simplemente importa una planilla con información detallada sobre los consumos estimados que
realiza anualmente cada cliente, incluyendo los productos específicos y los precios a los que se efectúan las transacciones. \n
Esto permite alimentar al modelo con datos reales o estimados de tus clientes actuales o potenciales, creando así las proyecciones de
mercado. \n Utiliza la sección “Para aprender” para guiarte en ejemplos de casos de estudio.`,
      mainPlanificationInfoCard: [
        {
          title: 'VENTAS PLANIFICADAS',
          description: 'Según el plan de ventas exportado, puedes visualizar las ventas planificadas de forma mensual o el acumulado anual.',
          icon: 'mdi-text-box',
        },
        {
          title: 'VENTAS REALES',
          description: 'Registra las ventas reales en tiempo real a medida que ocurran, y se actualizarán automáticamente en los tableros de seguimiento.',
          icon: 'mdi-text-box-check',
        },
        {
          title: 'DESEMPEÑO',
          description: 'Analiza el rendimiento de tu plan de ventas en función de los resultados reales, comparando el comportamiento planificado con los datos de ventas obtenidos. Esto te permitirá ajustar tu estrategia de manera precisa y alinearla con la realidad del mercado.',
          icon: 'mdi-chart-bar',
        }
      ]
    }
  },
  methods: {
    openWizard() {
      globalEmitter(WIZARD_STEPS_EVENTS.START_WIZARD, 0)
    }
  },
}
</script>