<template lang="pug">
v-card.mb-6(flat)
  v-card-title
    span Capacidad de Producción
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert" :showInfo="$route.query.help_production_capacity" :id="WIZARD_ELEMENTS_ID.HELP_PRODUCTION_CAPACITY")
  template(v-if="hasProducts")
    v-card-text
      v-form(@submit.prevent="handleFilter")
        v-row
          v-col(cols="12" md="4")
            v-text-field(
              label="Nombre del producto"
              v-model="filter.search"
              outlined
              hide-details
              clearable
              )
          v-col(cols="12" md="4")
            v-select(
              label="Línea de negocio"
              v-model="filter.base_product__line_of_business"
              outlined
              hide-details
              item-text="name"
              item-value="id"
              clearable
              :items="linesOfBusiness")
          v-col(cols="12" md="auto")
            v-btn(color="primary" block type="submit") Filtrar
          v-col(cols="12" md="auto")
            v-btn(color="primary" block  @click="$refs.productionCapacityImportFormModal.openDialog()")
              v-icon mdi-import
              span Importar

    v-simple-table(key="customer-classification")
      template(v-slot:default)
        thead(key="customer-classification-header")
          tr
            th SKU
            th Nombre
            th Capacidad
            th Capacidad Combinada
            th Bloqueado
            th(style="width: 80px") Acciones
        tbody(key="customer-classification-body")
          tr(v-for="item, index in items" :key="item.id")
            td {{item.sku}}
            td {{item.name}}
            td
              v-icon(v-if="item.monthly_production_capacity > 0 || item.has_shared_capacity" color="#FF9F40") mdi-check-circle
              div(v-else) -
            td
              v-icon(v-if="item.has_shared_capacity" color="#FF9F40") mdi-check-circle
              div(v-else) -
            td.d-flex.align-center
              template(v-if="item.shared_capacity_group")
                v-icon(color="#FB5050" left) mdi-lock-check
                div.font-weight-bold.primary--text(style="width:200px; cursor: pointer;" @click="$refs.productionCapacityFormModal.openDialog(item.shared_capacity_group)") {{ item.shared_capacity_group_name }}

              div(v-else) -
            td
              div.font-weight-bold(
                style="width:200px; cursor: pointer;"
                :class="item.shared_capacity_group ? 'text--disabled' : 'primary--text'"
                @click="!item.shared_capacity_group ? $refs.productionCapacityFormModal.openDialog(item.id) && globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_EDIT_PRODUCTION_CAPACITY) : null"
                :id="index === 0 ? WIZARD_ELEMENTS_ID.BTN_EDIT_PRODUCTION_CAPACITY : null"
              ) Editar Capacidad
    v-pagination(v-model="pagination.page" :length="pagination.pages" @input="fetchItems")

  EmptyState(
    v-else
    title="Capacidad de Producción"
    description="Aun no tienes productos SKU registrados, crea tu primer producto SKU para comenzar a gestionar capacidad de producción."
    primaryActionText="Crear Producto SKU"
    imgPath="/img/empty_state_1.png"
    @click-primary-action="$router.push('/productos?tab=1')"
  )

  CustomerClassificationFormModal(ref="customerClassificationFormModal" @save="fetchItems")
  CustomConfirmDialog(ref="confirmDialog")
  ProductImportFormModal(ref="productImportFormModal" @save="fetchItems")
  ProductionCapacityFormModal(ref="productionCapacityFormModal" @save="fetchItems")
  ProductionCapacityImportFormModal(ref="productionCapacityImportFormModal" @save="fetchItems")
</template>

<script>
import { mapActions } from 'vuex'
import CustomerClassificationFormModal from '@/components/customers/CustomerClassificationFormModal.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import ProductImportFormModal from '@/components/products/ProductImportFormModal.vue'
import ShowInfoBtn from '../common/ShowInfoBtn.vue'
import InfoAlert from '../common/InfoAlert.vue'
import EmptyState from '../common/EmptyState.vue'
import ProductionCapacityFormModal from './ProductionCapacityFormModal.vue'
import ProductionCapacityImportFormModal from './ProductionCapacityImportFormModal.vue'
import { WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  components: {
    CustomerClassificationFormModal,
    ProductionCapacityImportFormModal,
    ProductionCapacityFormModal,
    ProductImportFormModal,
    CustomConfirmDialog,
    ShowInfoBtn,
    InfoAlert,
    EmptyState
  },
  data () {
    return {
      WIZARD_STEPS_EVENTS,
      WIZARD_ELEMENTS_ID,
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 20
      },
      filter: {
        search: '',
        base_product__line_of_business: null
      },
      items: [],
      linesOfBusiness: [],
      alertMessage: "Este es tu centro de gestión de capacidades. Aquí puedes ingresar las capacidades de producción o adquisición de tus productos, tanto de manera unitaria como conjunta. <b>Esto permitirá una planificación precisa y optimizada según las capacidades reales de tu negocio</b>. <br><br><b>Capacidad:</b> En esta sección, podrás definir la cantidad que tu empresa es capaz de producir o adquirir para cada producto. Este dato es fundamental para que Plan2Sell pueda establecer de manera precisa tu capacidad de venta y generar un plan de ventas realista, ajustado a tus posibilidades.<br><br><b>Capacidades compartidas:</b> Se refiere a aquellas que involucran varios productos que utilizan los mismos recursos o maquinaria. Por ejemplo, si tienes una línea de producción que fabrica diferentes productos, la capacidad compartida te permitirá asignar de forma eficiente los recursos entre esos productos, evitando sobrecargar tus capacidades de producción o abastecimiento.<br><br><b>Capacidades propias:</b> Estas son las capacidades exclusivas de cada producto, donde no hay competencia por los recursos. Al definir capacidades propias, podrás evaluar de manera individual la producción o adquisición de cada producto, permitiendo un análisis más detallado y preciso de los tiempos, recursos y disponibilidad para cada línea o producto específico."
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('product', ['listProducts', 'deleteProduct', 'listLineOfBusiness', 'checkHasProducts']),
    async fetchItems () {
      const params = {
        ...this.filter,
        page: this.pagination.page,
        page_size: this.pagination.pageSize
      }
      const { data } = await this.listProducts({ params })
      this.items = data.results || []
      this.pagination.pages = Math.ceil(data.count / this.pagination.pageSize)
    },
    async handleFilter () {
      this.pagination.page = 1
      await this.fetchItems()
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar producto',
        '¿Está seguro que desea eliminar este producto?',
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteProduct(id)
        await this.fetchItems()
      }
    },
    async fetchLinesOfBusiness () {
      const { data } = await this.listLineOfBusiness()
      this.linesOfBusiness = data
    }
  },
  computed: {
    hasProducts() {
      return this.$store.state.product.hasProducts
    },
  },
  async created () {
    this.fetchLinesOfBusiness()
    this.checkHasProducts()
    await this.fetchItems()
    if (this.items.length === 0 && this.$refs.infoAlert) {
      this.$refs.infoAlert.showAlert()
    }
  }
}
</script>