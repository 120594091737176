// case modification warning
export const CASE_MODIFICATION_WARNING = 'Esta acción modificará el caso los casos de potencial comercial asociados, para no perder la información, por favor, asegúrese de descargar los casos antes de continuar.'

export const WIZARD_ELEMENTS_ID = {
  NAV_CLIENTS: 'nav-clients',
  NAV_PRODUCTS: 'nav-products',
  NAV_PRODUCTION_CAPACITY: 'nav-production-capacity',
  NAV_BASE_PRODUCTS: 'nav-base-products',
  NAV_COMMERCIAL_PROJECTION: 'nav-commercial-projection',

  BTN_CREATE_CLIENT: 'btn-create-client',
  BTN_CREATE_PRODUCT: 'btn-create-product',
  BTN_CREATE_BASE_PRODUCT: 'btn-create-base-product',
  BTN_CREATE_CLIENT_CLASSIFICATION: 'btn-create-client-classification',
  BTN_CREATE_LINE_OF_BUSINESS: 'btn-create-line-of-business',
  BTN_CREATE_COMMERCIAL_PROJECTION: 'btn-commercial-projection',
  BTN_CREATE_BASE_PRODUCT_EXAMPLE: 'btn-create-base-product-example',
  BTN_EDIT_PRODUCTION_CAPACITY: 'btn-edit-production-capacity',
  BTN_ADD_COMMERCIAL_PROJECTION_ITEM: 'btn-add-commercial-projection-item',
  BTN_ANALIYSIS_COMMERCIAL_PROJECTION: 'btn-analysis-commercial-projection',


  HELP_CLIENTS_CLASSIFICATION: 'help-clients-classification',
  HELP_PRODUCTION_CAPACITY: 'help-production-capacity',
  HELP_PRODUCTS: 'help-products',
  HELP_BASE_PRODUCTS: 'help-base-products',
  HELP_COMMERCIAL_PROJECTION: 'help-commercial-projection',
  HELP_LINE_OF_BUSINESS: 'help-line-of-business',
  HELP_PRODUCT_ATTRIBUTES: 'help-product-attributes',
  HELP_MARKET_PRICE: 'help-market-price',
  HELP_MONTHLY_PRODUCTION_CAPACITY: 'help-monthly-production',

  SECTION_OPTIMAL_MIX: 'section-optimal-mix',
  SECTION_MARKET_SIZE: 'section-market-size',
  SECTION_SALE_PLAN: 'section-sale-plan',
  ANALIYSIS_COMMERCIAL_PROJECTION: 'analysis-commercial-projection',
}


export const WIZARD_STEPS_EVENTS = {
  START_WIZARD: 'start_wizard',
  OPEN_LAST_WIZARD_STEP: 'open_last_wizard_step',
  CLIENT_CLASSIFICATION_CREATED: 'client_classification_created',
  CLIENT_CREATED: 'client_created',
  PRODUCT_CREATED: 'product_created',
  BASE_PRODUCT_CREATED: 'base_product_created',
  LINE_OF_BUSINESS_CREATED: 'line_of_business_created',
  PRODUCTION_CAPACITY_CREATED: 'production_capacity_created',
  COMMERCIAL_PROJECTION_CREATED: 'commercial_projection_created',
  COMMERCIAL_PROJECTION_ITEM_CREATED: 'commercial_projection_item_created',
  NAV_TO_CLIENTS: 'nav_to_clients',
  ON_CLICK_CREATE_CLIENT: 'on_click_create_client',
  ON_CLICK_CREATE_CLIENT_CLASSIFICATION: 'on_click_create_client_classification',
  ON_CLICK_CREATE_LINE_OF_BUSINESS: 'on_click_create_line_of_business',
  ON_CLICK_CREATE_BASE_PRODUCT_EXAMPLE: 'on_click_create_base_product_example',
  ON_CLICK_CREATE_PRODUCT: 'on_click_create_product',
  ON_CLICK_CREATE_BASE_PRODUCT: 'on_click_create_base_product',
  ON_CLICK_EDIT_PRODUCTION_CAPACITY: 'on_click_edit_production_capacity',

  ON_CLICK_CREATE_COMMERCIAL_PROJECTION: 'on_click_create_commercial_projection',
  ON_CLICK_CREATE_COMMERCIAL_PROJECTION_ITEM: 'on_click_create_commercial_projection_item',
  ON_CLICK_ANALIYSIS_COMMERCIAL_PROJECTION: 'on_click_analysis_commercial_projection',
}


const _getWizardDescriptionTemplate = (description, nStep=1) => {

  const getStepNumberYTemplate = (n) => `
    <div style="border-radius: 50%;
      ${n <= nStep ? 'background-color: #5575DF; color: white;' : 'background-color: white; color: #5575DF;'}
      width: 24px; height: 24px; display: flex; justify-content: center; align-items: center; font-size: 14px; font-weight: bold; border: 2px solid #5575DF;">
      ${n}
    </div>
  `
  const getDividerTemplate = () => `
    <div style="height: 3px; width: 24px; background-color: #5575DF;"></div>
  `

  return `
    <div>
      <div style="display: flex; justify-content: center; align-items: center; gap: 0px; margin: 16px 0">
        ${getStepNumberYTemplate(1)}
        ${getDividerTemplate()}
        ${getStepNumberYTemplate(2)}
        ${getDividerTemplate()}
        ${getStepNumberYTemplate(3)}
        ${getDividerTemplate()}
        ${getStepNumberYTemplate(4)}
      </div>

      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 16px;">
        <img src="/img/wizard-modal.png" style="with: 100px; height: 100px; maring: auto;"/>
        <p style="text-align: center;">${description}</p>
      </div>
    </div>
`
}

/**
 * Wizard steps
 * @type {Array}
 * @property {String} title
 * @property {String} description
 * @property {String} elementId
 * @property {String} path
 * @property {String} eventToNext
 */
export const WIZARD_STEPS = [
  {
    title: 'N°1 Ingreso de Clientes',
    description: _getWizardDescriptionTemplate('Para comenzar, necesitamos que ingreses los datos de tus clientes actuales y clientes potenciales según tu mercado.'),
    nextBtnText: 'Comenzar',
  },
  {
    title: 'N°1 Ingreso de Clientes',
    description: _getWizardDescriptionTemplate('En el menú de la izquierda puede navegar entre los distintos modulos. Haga clic en "Clientes" para comenzar.'),
    elementId: WIZARD_ELEMENTS_ID.NAV_CLIENTS,
    showButtons: ['close'],
    eventToNext: WIZARD_STEPS_EVENTS.NAV_TO_CLIENTS,
  },
  {
    title: 'N°1 Ingreso de Clientes',
    description: _getWizardDescriptionTemplate('Cada modulo tiene una sección de ayuda, donde podrá encontrar información relevante para completar la información requerida.'),
    elementId: WIZARD_ELEMENTS_ID.HELP_CLIENTS_CLASSIFICATION,
  },
  {
    title: 'N°1 Ingreso de Clientes',
    description: _getWizardDescriptionTemplate('Para comenzar, haga clic en el botón "Nueva Clasificación" y complete los datos requeridos.'),
    elementId: WIZARD_ELEMENTS_ID.BTN_CREATE_CLIENT_CLASSIFICATION,
    clickElement: true,
    eventToDestroy: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_CLIENT_CLASSIFICATION,
    eventToNext: WIZARD_STEPS_EVENTS.CLIENT_CLASSIFICATION_CREATED,
    showButtons: ['close'],
  },
  {
    title: 'N°1 Ingreso de Clientes',
    description: _getWizardDescriptionTemplate('¡Ya ingresaste tu primera Clasificación de Clientes! Ahora vamos a agregar nuestros clientes. Ingresa los datos de tu cliente. ¡La comuna resultará vital para visualizar tus zonas de mayor potencial comercial!.'),
    elementId: WIZARD_ELEMENTS_ID.BTN_CREATE_CLIENT,
    eventToDestroy: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_CLIENT,
    eventToNext: WIZARD_STEPS_EVENTS.CLIENT_CREATED,
    showButtons: ['close'],
    clickElement: true,
    delay: 600,
  },
  {
    title: 'N°1 Ingreso de Clientes',
    description: _getWizardDescriptionTemplate('¡Ya ingresaste tu primer Cliente! Recuerda que puedes importar tu base de datos desde planilla o ingresarlos uno por uno manualmente.'),
  },

  // Linea de negocio
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('A continuación, necesitamos que ingreses los datos de tus productos.', 2),
    path: '/productos?help_line_of_business=true',
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('¿Por qué es importante? Al conocer tus productos, Plan2Sell puede ayudarte a optimizar tu mix de productos y maximizar tu rentabilidad.', 2),
    path: '/productos?help_line_of_business=true',
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('Para agregar una línea de negocio, haga clic en el botón "Agregar Línea de Negocio" y complete los datos requeridos.', 2),
    elementId: WIZARD_ELEMENTS_ID.BTN_CREATE_LINE_OF_BUSINESS,
    eventToDestroy: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_LINE_OF_BUSINESS,
    showButtons: ['close'],
    eventToNext: WIZARD_STEPS_EVENTS.LINE_OF_BUSINESS_CREATED,
    path: '/productos',
  },
  // Producto Base
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('¡Ya ingresaste tu primera Línea de Negocio! Ahora vamos a agregar productos.', 2),
    path: '/productos',
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('¿Por qué es importante? Al conocer tus productos, Plan2Sell puede ayudarte a optimizar tu mix de productos y maximizar tu rentabilidad.', 2),
    path: '/productos',
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('Haga clic en "Añadir Producto" para continuar.', 2),
    elementId: WIZARD_ELEMENTS_ID.BTN_CREATE_BASE_PRODUCT,
    eventToNext: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_BASE_PRODUCT,
    showButtons: ['close'],
    path: '/productos',
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('Aquí puedes ver un ejemplo de producto base', 2),
    elementId: WIZARD_ELEMENTS_ID.BTN_CREATE_BASE_PRODUCT_EXAMPLE,
    eventToDestroy: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_BASE_PRODUCT_EXAMPLE,
    path: '/productos-base/nuevo',
    eventToNext: WIZARD_STEPS_EVENTS.BASE_PRODUCT_CREATED,
    showButtons: ['close'],
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('¡Ya ingresaste tu primer Producto Base! Ahora vamos a agregar nuestros productos.', 2),
  },

  // Producto SKU
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('Para agregar un producto, haga clic en el botón "Agregar Producto" y complete los datos requeridos.', 2),
    elementId: WIZARD_ELEMENTS_ID.BTN_CREATE_PRODUCT,
    path: '/productos',
    updateQueryParams: { tab: 1 },
    eventToNext: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_PRODUCT,
    showButtons: ['close'],
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('El precio de mercado es un dato importante para el análisis de tus productos.', 2),
    path: '/productos/nuevo',
    destroyOnNext: true,
    eventToNext: WIZARD_STEPS_EVENTS.PRODUCT_CREATED,
  },
  {
    title: 'N°2 Ingreso de Productos',
    description: _getWizardDescriptionTemplate('¡Ya ingresaste tu primer Producto! Recuerda que puedes importar tu base de datos desde planilla o ingresarlos uno por uno manualmente.', 2),
  },
  // Capacidad de Producción
  {
    title: 'N°3 Capacidad de Producción',
    description: _getWizardDescriptionTemplate('Ahora, necesitamos que ingreses las capacidades de producción o adquisición de tus productos.', 3),
    path: '/capacidad-produccion',
  },
  {
    title: 'N°3 Capacidad de Producción',
    description: _getWizardDescriptionTemplate('¿Por qué es importante? Conocer tu capacidad de producción te permitirá planificar tus ventas de manera más eficiente y optimizar tus recursos.', 3),
    path: '/capacidad-produccion',
  },
  {
    title: 'N°3 Capacidad de Producción',
    description: _getWizardDescriptionTemplate('Para agregar la capacidad de producción, haga clic en el botón "Editar" y complete los datos requeridos.', 3),
    path: '/capacidad-produccion',
    eventToNext: WIZARD_STEPS_EVENTS.ON_CLICK_EDIT_PRODUCTION_CAPACITY,
    elementId: WIZARD_ELEMENTS_ID.BTN_EDIT_PRODUCTION_CAPACITY,
    showButtons: ['close'],
  },
  {
    title: 'N°3 Capacidad de Producción',
    description: _getWizardDescriptionTemplate('Considera las restricciones de capacidad para un producto de manera única o múltiples productos que se producen o adquieren juntos. Si la producción o adquisición de un producto afecta la disponibilidad de otro, este modelo integra esas dependencias para evitar sobreestimaciones y optimizar la planificación de recursos.', 3),
  },
  {
    title: 'N°3 Capacidad de Producción',
    description: _getWizardDescriptionTemplate('Puedes hacer que tu capacidad varie mes a mes, y puedes ingresarlo de manera porcentual o unitariamente.', 3),
    destroyOnNext: true,
    eventToNext: WIZARD_STEPS_EVENTS.PRODUCTION_CAPACITY_CREATED,
  },
  {
    title: 'N°3 Capacidad de Producción',
    description: _getWizardDescriptionTemplate('¡Ya ingresaste tu primera restricción de capacidad! Recuerda que puedes restringir la capacidad de varios productos en conjunto.', 3),
  },
  // Proyección Comercial
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('Has completado la configuración de tu negocio. Ahora puedes utilizar todas las herramientas de Plan2Sell para analizar tu mercado y optimizar tus ventas. Vamos ahora a crear un caso personalizado.', 4),
    path: '/proyeccion-comercial',
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('¿Por qué es importante? Con tu potencial comercial podrás visualizar el tamaño de tu mercado, optimizar tu mix de productos y planificar tus ventas.', 4),
    path: '/proyeccion-comercial',
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('Para comenzar, haga clic en "Añadir caso" y complete los datos requeridos.', 4),
    elementId: WIZARD_ELEMENTS_ID.BTN_CREATE_COMMERCIAL_PROJECTION,
    path: '/proyeccion-comercial',
    eventToDestroy: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_COMMERCIAL_PROJECTION,
    eventToNext: WIZARD_STEPS_EVENTS.COMMERCIAL_PROJECTION_CREATED,
    showButtons: ['close'],
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('Ahora puedes agregar items a tu potencial comercial. Haga clic en "Añadir Producto" y complete los datos requeridos.', 4),
    elementId: WIZARD_ELEMENTS_ID.BTN_ADD_COMMERCIAL_PROJECTION_ITEM,
    eventToNext: WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_COMMERCIAL_PROJECTION_ITEM,
    showButtons: ['close'],
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('En esta sección, ingresa tu caso de estudio personalizando los consumos de tus productos para cada cliente, ya sean estimados o conocidos. Estos consumos determinarán el tamaño de tu mercado. Aprovecha esta oportunidad para definir con precisión el caso que deseas analizar, adaptándolo a las necesidades específicas de tu negocio.', 4),
    destroyOnNext: true,
    eventToNext: WIZARD_STEPS_EVENTS.COMMERCIAL_PROJECTION_ITEM_CREATED
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('Recuerda que puedes importar desde planilla o ingresar tu caso para cada producto uno por uno manualmente.', 4),
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('¡Ya ingresaste tu caso! ¿Estas listo para analizar los resultados?', 4),
    path: '/proyeccion-comercial',
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('Ahora puedes analizar tu potencial comercial, haciendo clic en el botón "Análisis"', 4),
    elementId: WIZARD_ELEMENTS_ID.BTN_ANALIYSIS_COMMERCIAL_PROJECTION,
    eventToNext: WIZARD_STEPS_EVENTS.ON_CLICK_ANALIYSIS_COMMERCIAL_PROJECTION,
    showButtons: ['close'],
    path: '/proyeccion-comercial',
  },
  {
    title: 'N°4: Creación de tu caso de estudio',
    description: _getWizardDescriptionTemplate('Recuerda que tienes tres tipos de análisis en Plan 2 Sell: Potencial Comercial, Mix Óptimo de Ventas sugerido por la plataforma y Plan de Ventas personalizado para tu negocio. Utiliza los botones de ayuda y los filtros disponibles para realizar un análisis exhaustivo y aprovechar al máximo el potencial de tu negocio.', 4),
  },
  {
    title: 'N°5 Análisis de Proyección Comercial',
    description: _getWizardDescriptionTemplate('En el apartado de Casos y Análisis, puedes crear y comparar diferentes escenarios para enfocar tus esfuerzos de venta y evaluar distintos desempeños. Esto te permitirá tomar decisiones informadas y estratégicas para maximizar tus resultados.', 4),
    elementId: WIZARD_ELEMENTS_ID.ANALIYSIS_COMMERCIAL_PROJECTION,
  },
  {
    title: 'N°5 Análisis de Proyección Comercial',
    description: _getWizardDescriptionTemplate('No te olvides de revisar nuestro apartado de <b>Plan 2 Sell: Casos para análisis de tu negocio.</b> En este apartado, Plan 2 Sell te proporcionará sugerencias de casos típicos. Estas guías están diseñadas para convertir tu conocimiento único del negocio en estrategias concretas, ayudándote a tomar acciones informadas y efectivas', 4),
  },
  {
    title: 'N°5 Análisis de Proyección Comercial',
    description: _getWizardDescriptionTemplate('¡Felicidades! Has completado la guía de Plan 2 Sell. Ahora puedes comenzar a analizar tu mercado y optimizar tus ventas. ¡Buena suerte!', 4),
    nextBtnText: 'Finalizar',
  }
]
