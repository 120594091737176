import axios from '../../plugins/axios'
import { getCurrentBusinessId } from '../../utils/authUtils'



export const createLineOfBusiness = ({ dispatch }, { data } = {} ) => {
  return axios.post(`/product/business/${getCurrentBusinessId()}/line-of-business/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
    .finally(() => {
      dispatch('checkHasLineOfBusiness')
    })
}

export const listLineOfBusiness = () => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/line-of-business/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editLineOfBusiness = (_, { id, data } = {} ) => {
  return axios.patch(`/product/business/${getCurrentBusinessId()}/line-of-business/${id}/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteLineOfBusiness = ({ dispatch }, id) => {
  return axios.delete(`/product/business/${getCurrentBusinessId()}/line-of-business/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
    .finally(() => {
      dispatch('checkHasLineOfBusiness')
    })
}

export const getLineOfBusiness = (_, id) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/line-of-business/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const checkHasLineOfBusiness = ({ commit }) => {
  const params = {
    page_size: 1
  }
  return axios.get(`/product/business/${getCurrentBusinessId()}/line-of-business/`, { params })
    .then(response => {
      const hasLineOfBusiness = response.data.length > 0
      commit('SET_HAS_LINE_OF_BUSINESS', hasLineOfBusiness)
      return { data: hasLineOfBusiness, response, status: response.status }
    })
    .catch(error => ({ error }))
}

export const attributeAutosuggest = () => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/base-products/attribute-autosuggest/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const unitOfMeasureAutosuggest = () => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/base-products/unit-of-measure-autosuggest/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const createBaseProduct = ({ dispatch }, { data } = {} ) => {
  return axios.post(`/product/business/${getCurrentBusinessId()}/base-products/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
    .finally(() => {
      dispatch('checkHasBaseProducts')
    })
}

export const listBaseProducts = (_, { params }) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/base-products/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editBaseProduct = (_, { id, data } = {} ) => {
  return axios.patch(`/product/business/${getCurrentBusinessId()}/base-products/${id}/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteBaseProduct = ({ dispatch }, id) => {
  return axios.delete(`/product/business/${getCurrentBusinessId()}/base-products/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
    .finally(() => {
      dispatch('checkHasBaseProducts')
    })
}

export const checkHasBaseProducts = ({ commit }) => {
  const params = {
    page_size: 1
  }
  return axios.get(`/product/business/${getCurrentBusinessId()}/base-products/`, { params })
    .then(response => {
      const hasBaseProduct = response.data.results.length > 0
      commit('SET_HAS_BASE_PRODUCTS', hasBaseProduct)
      return { data: hasBaseProducts, response, status: response.status }
    })
    .catch(error => ({ error }))
}

export const getBaseProduct = (_, id) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/base-products/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}


export const createProduct = ({ dispatch }, { data } = {} ) => {
  return axios.post(`/product/business/${getCurrentBusinessId()}/products/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
    .finally(() => {
      dispatch('checkHasProducts')
    })
}

export const listProducts = (_, { params }) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/products/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editProduct = (_, { id, data } = {} ) => {
  return axios.patch(`/product/business/${getCurrentBusinessId()}/products/${id}/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const updateProductProductionCapacity = (_, { id, data } = {} ) => {
  return axios.put(`/product/business/${getCurrentBusinessId()}/products/${id}/update-production-capacity/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteProduct = ({ dispatch }, id) => {
  return axios.delete(`/product/business/${getCurrentBusinessId()}/products/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
    .finally(() => {
      dispatch('checkHasProducts')
    })
}

export const getProduct = (_, id) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/products/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const checkHasProducts = ({ commit }) => {
  const params = {
    page_size: 1
  }
  return axios.get(`/product/business/${getCurrentBusinessId()}/products/`, { params })
    .then(response => {
      const hasProduct = response.data.results.length > 0
      commit('SET_HAS_PRODUCTS', hasProduct)
      return { data: hasProducts, response, status: response.status }
    })
    .catch(error => ({ error }))
}

export const importProducts = (_, { data }) => {
  return axios.post(`/product/business/${getCurrentBusinessId()}/products/import/`,  data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const importProductionCapacity = (_, { data }) => {
  return axios.post(`/product/business/${getCurrentBusinessId()}/products/import-production-capacity/`,  data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const listAttributeOptions = (_, { params }) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/attribute-options/`, { params })
     .then(response => ({ data: response.data, response, status: response.status }))
     .catch(error => ({ error }))
}

export const bulkDeleteProducts = (_, ids) => {
  return axios.delete(`/product/business/${getCurrentBusinessId()}/products/bulk-delete/`, { data: { ids } })
     .then(response => ({ data: response.data, status: response.status }))
     .catch(error => ({ error }))
}

export const bulkDeleteBaseProducts = (_, ids) => {
  return axios.delete(`/product/business/${getCurrentBusinessId()}/base-products/bulk-delete/`, { data: { ids } })
      .then(response => ({ data: response.data, status: response.status }))
      .catch(error => ({ error }));
}

export const downloadProducts = (_, params) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/products/export/`, { params, responseType: 'blob'})
    .catch(error => ({ error }))
}

export const downloadProductCapacity = (_, params) => {
  return axios.get(`/product/business/${getCurrentBusinessId()}/products/export-capacity/`, { params, responseType: 'blob'})
    .catch(error => ({ error }))
}