<template lang="pug">
v-app
  v-app-bar(app color="white" flat dark)
    v-app-bar-nav-icon.d-lg-none(@click.stop="drawer = !drawer" color="primary")
    v-spacer
    v-menu(offset-y)
      //- template(v-slot:activator="{ on, attrs }")
      //-   v-btn(v-bind="attrs" v-on="on" color="primary" text rounded)
      //-     v-icon mdi-domain
      //-     span(v-if="user") {{membershipContext.business_name}}
      //-     v-icon mdi-triangle-small-down
      v-list
        v-list-item(v-if="membership.id !== membershipContext.id" v-for="membership in myMemberships" @click="setMembershipContext(membership)")
          v-list-item-title {{membership.business_name}}
    v-menu(offset-y)
      template(v-slot:activator="{ on, attrs }")
        v-btn(v-bind="attrs" v-on="on" color="primary" text rounded)
          v-icon mdi-account
          span(v-if="user") {{user.first_name}}
          v-icon mdi-triangle-small-down
      v-list
        v-list-item(@click="onExit")
          v-list-item-icon
            v-icon mdi-logout
          v-list-item-title Salir
  v-navigation-drawer(v-model="drawer" app levation=10 width="255px" color="primary" dark)
    .text-right.mt-3.mr-3.d-lg-none
      v-app-bar-nav-icon(@click.stop="drawer = !drawer" color="primary")
    v-img.my-md-12(src="../assets/img/brand/logotipo.png" width=200 style="margin: auto;" @click="$router.push('/')")
    v-list(nav dense varient="primary" expand)
      template(v-for="(item, i) in menuItems")
        //- Link Simple
        template(v-if="!item.divider")
          v-list-item(:key="i" :disabled="item.disabled" @click="onNavClick(item)" :to="item.to" active-class="v-list-item--active" :id="item.id")
            v-list-item-icon.mr-1
              v-icon {{item.icon}}
              v-icon.ml-2 {{item.numberIcon}}
            v-list-item-content
              v-list-item-title(v-text="item.text")
        //- Divider
        template(v-else)
          h5.mt-md-6.mb-md-2.mx-2(style="font-weight: 700; color: white;") {{item.text}}
  v-main
    slot(style="height: 100%")

  v-fab-transition(v-if="wizardIsActive")
    v-btn.mb-16(fab color="white" dark fixed bottom right @click="globalEmitter(WIZARD_STEPS_EVENTS.OPEN_LAST_WIZARD_STEP)" width="90" height="90")
      v-img.ma-2(src="/img/wizard_fab.png" width="64" contain)
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '../constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  data() {
    return {
      WIZARD_STEPS_EVENTS,
      businesses: [],
      drawer: true,
      menuItems: [
        {
          text: 'Home',
          to: '/',
          icon: 'mdi-home',
        },
        {
          text: 'Para aprender',
          to: '/aprender',
          icon: 'mdi-notebook-edit-outline'
        },
        {
          divider: true,
          text: 'CONFIGURA TU NEGOCIO'
        },
        {
          text: 'Clientes',
          to: '/clientes',
          icon: 'mdi-account-group',
          numberIcon: 'mdi-numeric-1',
          id: WIZARD_ELEMENTS_ID.NAV_CLIENTS,
          emit: WIZARD_STEPS_EVENTS.NAV_TO_CLIENTS
        },
        {
          text: 'Productos',
          to: '/productos',
          icon: 'mdi-package-variant-closed',
          numberIcon: 'mdi-numeric-2',
          id: WIZARD_ELEMENTS_ID.NAV_PRODUCTS
        },
        {
          text: 'Capacidad de Producción',
          to: '/capacidad-produccion',
          icon: 'mdi-chart-areaspline',
          numberIcon: 'mdi-numeric-3',
          id: WIZARD_ELEMENTS_ID.NAV_PRODUCTION_CAPACITY
        },
        {
          divider: true,
          text: 'TUS CASOS DE ESTUDIO'
        },
        {
          text: 'Casos y análisis',
          to: '/proyeccion-comercial',
          icon: 'mdi-star-plus',
          id: WIZARD_ELEMENTS_ID.NAV_COMMERCIAL_PROJECTION
        },
        {
          divider: true,
          text: 'SEGUIMIENTO'
        },
        {
          text: 'Planificación y Seguimiento',
          icon: 'mdi-chart-box-outline',
          to: '/planes',
        },
        {
          divider: true,
          text: 'CONFIGURACIÓN',
        },
        {
          text: 'Configuración',
          to: '/negocio',
          icon: 'mdi-cog-outline',
        },
      ]
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('auth', ['logout']),
    ...mapActions('business', ['listBusinesses']),
    ...mapActions('app_config', ['setCurrentBusiness']),
    ...mapActions('app', ['setMembershipContext']),
    onExit() {
      this.logout()
      this.$router.push('/login')
    },
    onNavClick(item) {
      if (item.emit) {
        globalEmitter(item.emit)
      }

      if (item.to.split('?')[0] === this.$route.path) {
        return
      }
      this.$router.push(item.to)
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('app', ['myMemberships']),
    ...mapGetters('app', ['membershipContext', 'wizardIsActive']),
  },
  async created() {
    const response = await this.listBusinesses({ params: { page_size: 100 } })

    if (response.status === 200) {
      this.businesses = response.data.results
    }
  }
}
</script>

<style lang="scss">
.v-list-item--active {
  background-color: white !important;
  .v-list-item__title {
    color: #5575DF !important;
  }
  .v-icon {
    color: #5575DF !important;
  }
}
</style>